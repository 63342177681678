import React, { useState, useEffect } from 'react';
import { useQuery, gql, ApolloClient, InMemoryCache } from '@apollo/client';

import MainLayout from '@hoc/layout';
import Seo from '@components/seo/seo';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import SearchFilters from '@components/search-filters/search-filters';
import CarTile from '@components/car-tile/car-tile';
import HandleLoading from '@hoc/handle-loading/handle-loading';

import * as s from './leasing-wynajem.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

import { MIN, MAX } from '@components/search-filters/search-filters';

const CAR_QUERY = gql`
    query CarQuery(
        $after: String,
        $typeOfCar: String,
        $vehicleCompany: String,
        $vehicleModel: String,
        $leasingRateMin: Int,
        $leasingRateMax: Int,
        $customerCompany: Boolean,
        $financingType: String,
        $fuel: String,
        $transmission: String,
        $enginePower: Int,
        $bodyType: String
        $seats: Int,
        $year: Int
    ) {
        cars(
            first: 10
            after: $after
            where: {
                typeOfCar: $typeOfCar,
                vehicleCompany: $vehicleCompany,
                vehicleModel: $vehicleModel,
                leasingRateMin: $leasingRateMin,
                leasingRateMax: $leasingRateMax,
                customerCompany: $customerCompany,
                financingType: $financingType,
                fuel: $fuel,
                transmission: $transmission,
                enginePower: $enginePower,
                bodyType: $bodyType
                seats: $seats,
                year: $year
            }
        ) {
            pageInfo {
                hasNextPage
                endCursor
                total
            }
            nodes {
                slug
                car {
                    fuelType
                    gearboxType
                    mainImg
                    year
                    price
                    installmentFrom
                    name
                    typeOfCar
                }
            }
        }
    }
`;

const generalClient = new ApolloClient({
	uri: 'https://wp.clicklease.pl/graphql',
	cache: new InMemoryCache()
});

const WyszukiwarkaPojazdow = (props) => {

	const [vehicleTypeCar, setVehicleTypeCar] = useState(false);
	const [vehicleTypeTruck, setVehicleTypeTruck] = useState(false);
	const [vehicleTypeOther, setVehicleTypeOther] = useState(false);

	const [vehicleCompany, setVehicleCompany] = useState(undefined);
	const [vehicleModel, setVehicleModel] = useState(undefined);
	const [leasingRate, setLeasingRate] = useState([MIN, MAX]);
	const [customerCompany, setCustomerCompany] = useState(true);

	const [financingType, setFinancingType] = useState(undefined);
	const [fuel, setFuel] = useState(undefined);
	const [transmission, setTransmission] = useState(undefined);
	const [enginePower, setEnginePower] = useState(undefined);
	const [bodyType, setBodyType] = useState(undefined);
	const [seats, setSeats] = useState(undefined);
	const [year, setYear] = useState(undefined);


	const { loading, error, data, refetch, fetchMore } = useQuery(CAR_QUERY,{
		variables: {
			typeOfCar: `${vehicleTypeCar && 'true'}.${vehicleTypeTruck && 'true'}.${vehicleTypeOther && 'true'}`,
			vehicleCompany,
			vehicleModel,
			leasingRateMin: leasingRate[0],
			leasingRateMax: leasingRate[1],
			customerCompany,
			financingType,
			fuel,
			transmission,
			enginePower,
			bodyType,
			seats,
			year
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		client: generalClient
	});



	const [fieldsToReset, setFieldsToReset] = useState([]);

	const updateFilters = (filters) => {
		setVehicleTypeCar(filters.vehicleTypeCar);
		setVehicleTypeTruck(filters.vehicleTypeTruck);
		setVehicleTypeOther(filters.vehicleTypeOther);
		setVehicleCompany(filters.vehicleCompany);
		setVehicleModel(filters.vehicleModel);
		setLeasingRate(filters.leasingRate);
		setCustomerCompany(filters.customerCompany);
		setFinancingType(filters.financingType);
		setFuel(filters.fuel);
		setTransmission(filters.transmission);
		setEnginePower(filters.enginePower);
		setBodyType(filters.bodyType);
		setSeats(filters.seats);
		setYear(filters.year);

		refetch();
	}


	const getPojazdyEndString = () => {
		if(!data?.cars || data?.cars.pageInfo.total == 0) return 'ów';
		if(data?.cars.pageInfo.total == 1) return '';
		if(data?.cars.pageInfo.total > 4) return 'ów';
		return 'y';
	}

	return (
		<MainLayout>

			<Seo
				title='Wyszukiwarka pojazdów - leasing i wynajem samochodów'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
			/>

			<BreadCrumbs />

			<section className={`${s.filters_section} ${s_wrapper.apply}`}>
				<h1>Wyszukiwarka pojazdów</h1>
				<p className={`${s_text.subtitle} ${s.subtitle}`}>
					Współpraca z ponad 40 funduszami leasingowymi daje Nam możliwość doboru i przedstawienia
					najkorzystniejszej na rynku i najbardziej dopasowanej do potrzeb klienta oferty.
					Jakiej marki i koloru jest auto Twoich marzeń?
				</p>

				<SearchFilters classes={s.search_filters}
				               updateResults={(filters) => updateFilters(filters)}
				               fieldsToReset={fieldsToReset}
				               setFieldsToReset={(fields) => setFieldsToReset(fields)}
				/>


			</section>

			<section className={`${s.results_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

				<div className={s.top_bar}>
					<h2>Znaleziono {data?.cars.pageInfo.total || 0} pojazd{getPojazdyEndString()}</h2>

				</div>

				<div className={s.active_filters_wrapper}>
					{customerCompany ?
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'customerCompany'])}>
							Na firmę
						</div>
						:
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'customerCompany'])}>
							Na osobę prywatną
						</div>
					}
					{vehicleTypeCar &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'vehicleTypeCar'])}>
							Osobowy
						</div>
					}
					{vehicleTypeTruck &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'vehicleTypeTruck'])}>
							Dostawczy
						</div>
					}
					{vehicleTypeOther &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'vehicleTypeOther'])}>
							Inne
						</div>
					}
					{vehicleCompany &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'vehicleCompany'])}>
							{vehicleCompany}
						</div>
					}
					{vehicleModel &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'vehicleModel'])}>
							{vehicleModel}
						</div>
					}
					{leasingRate[0] > MIN &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'leasingRateLow'])}>
							Rata od {leasingRate[0]}
						</div>
					}
					{leasingRate[1] < MAX &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'leasingRateHigh'])}>
							Rata do {leasingRate[1]}
						</div>
					}
					{financingType &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'financingType'])}>
							{financingType}
						</div>
					}
					{fuel &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'fuel'])}>
							{fuel}
						</div>
					}
					{transmission &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'transmission'])}>
							{transmission}
						</div>
					}
					{enginePower &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'enginePower'])}>
							Od {enginePower} KM
						</div>
					}
					{/* {seats &&
                        <div onClick={() => setFieldsToReset((prev) => [...prev, 'seats'])}>
                            {seats === 6 ? 'Od' : ''} {seats} miejsc{seats === 6 || seats === 5 ? '' : 'a'}
                        </div>
                    } */}
					{bodyType &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'bodyType'])}>
							{bodyType}
						</div>
					}
					{year &&
						<div onClick={() => setFieldsToReset((prev) => [...prev, 'year'])}>
							Rok produkcji {year}
						</div>
					}
				</div>

				<div className={s.results}>
					<HandleLoading loading={loading && !data} error={error}>
						{data && data.cars.nodes.map((el, id) => (
							<CarTile
								key={id}
								classes={s.item}
								mainImg={el.car.mainImg}
								slug={el.slug}
								name={el.car.name}
								fuelType={el.car.fuelType}
								gearboxType={el.car.gearboxType}
								year={el.car.year}
								price={el.car.price}
								installmentFrom={el.car.installmentFrom}
								horizontal={true}
							/>
						))}
					</HandleLoading>
				</div>

				{
					data?.cars.pageInfo.hasNextPage &&
					<button onClick={() => {
						fetchMore({
							variables: {
								after: data.cars.pageInfo.endCursor,
								typeOfCar: `${vehicleTypeCar && 'true'}.${vehicleTypeTruck && 'true'}.${vehicleTypeOther && 'true'}`,
								vehicleCompany,
								vehicleModel,
								leasingRateMin: leasingRate[0],
								leasingRateMax: leasingRate[1],
								customerCompany,
								financingType,
								fuel,
								transmission,
								enginePower,
								bodyType,
								seats,
								year
							},
							updateQuery: (prev, { fetchMoreResult }) => {
								if (!fetchMoreResult) return prev;
								return {cars: {pageInfo: {...fetchMoreResult.cars.pageInfo}, nodes: [...prev.cars.nodes, ...fetchMoreResult.cars.nodes] } };
							}
						});
					}}
					        className={`${s_btns.btn_outline_white} ${s_btns.btn_fixed_width} ${s_btns.btn_arrow} ${s.more_btn}`} disabled={loading}>
						{loading ? 'Loading...' : 'Więcej'}
					</button>
				}

			</section>

			<ContactSection />

			<MapSection />

		</MainLayout>
	)
};

export default WyszukiwarkaPojazdow;